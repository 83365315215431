<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>{{ setLang('modules.budget.limit.expenses.*BudgetLimitsExpenses*.main-header') }}</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i>{{ setLang('modules.budget.limit.expenses.*BudgetLimitsExpenses*.second-header') }}</div>
        <b-tabs v-model="tabIndx">
            <b-tab title="Доходы" active>
                <div class="actions-tab in-tab">
                    <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                        <span class="left">
                                            <i class="icon icon-info"></i>
                                        </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-text>
                            <div class="info-button">
                                <span @click="infoCostForm(9)">Режим «Лимиты» <i class="icon icon-folder"></i></span>
                            </div>
                            <!-- <div class="info-button">
                                <span>Видеоинструкция <i class="icon icon-video"></i></span>
                            </div> -->
                            <div class="info-text">
                                <p>Данный режим предназначен для ввода, корректировки и просмотра прогнозного объема
                                    поступлений в местный бюджет и лимитов расходов администраторов бюджетных программ
                                    на предстоящий плановый период. </p>
                                <p>Режим состоит из следующих разделов:</p>
                                <p><span class="red bold">Доходы</span><br> – распределение прогнозного объема
                                    поступлений между уровнями бюджетов.</p>
                                <p><span class="red bold">Нормативы распределения</span><br> – ведение таблицы
                                    распределения поступлений бюджета между уровнями бюджетов (приказ МФ РК №404).</p>
                                <p><span class="red bold">Расходы</span><br> – прогнозирование лимитов расходов
                                    администраторов бюджетных программ.</p>
                            </div>
                        </b-dropdown-text>
                    </b-dropdown>
                    <!---------------Форма для скачивания отчета по лимитам--------------->
                    <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                    <span class="left">
                                        <i class="icon icon-download"></i> Скачать
                                    </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-item @click="incomeGenerationPSER">Формирование доходов ПСЭР</b-dropdown-item>
                    </b-dropdown>
                </div>
                <template v-if="region !== null && variant !== null">
                    <limit-form ref="limitform"
                                :year="parseInt(year)"
                                :obl="obl"
                                :reg="region"
                                :list-reg="listReg"
                                @currentYearData="currentYearData"
                                :list-variants="listVariants"
                                :data-type-dict="dataTypeDict"
                                :userRegion="userRegion"
                                :access-level="limitAccessLevel"
                    ></limit-form>
                </template>
            </b-tab>
            <b-tab title="Нормативы распределения" v-if="enableBudgDistr">
                <div class="actions-tab in-tab">
                    <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                        <span class="left">
                                            <i class="icon icon-info"></i>
                                        </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-text>
                            <div class="info-button">
                                <span @click="infoCostForm(8)">Режим «Лимиты» <i class="icon icon-folder"></i></span>
                            </div>
                            <!-- <div class="info-button">
                                <span>Видеоинструкция <i class="icon icon-video"></i></span>
                            </div> -->
                            <div class="info-text">
                                <p>Данный режим предназначен для ввода, корректировки и просмотра прогнозного объема
                                    поступлений в местный бюджет и лимитов расходов администраторов бюджетных программ
                                    на предстоящий плановый период. </p>
                                <p>Режим состоит из следующих разделов:</p>
                                <p><span class="red bold">Доходы</span><br> – распределение прогнозного объема
                                    поступлений между уровнями бюджетов.</p>
                                <p><span class="red bold">Нормативы распределения</span><br> – ведение таблицы
                                    распределения поступлений бюджета между уровнями бюджетов (приказ МФ РК №404).</p>
                                <p><span class="red bold">Расходы</span><br> – прогнозирование лимитов расходов
                                    администраторов бюджетных программ.</p>
                            </div>
                        </b-dropdown-text>
                    </b-dropdown>
                </div>
                <c-budg-distr :editable="editableBudgDistr"/>
            </b-tab>
            <!--                Вкладка по лимитам расходов-->
            <b-tab :title="getTabName()" v-if="enableLimitExpenses">
                <div class="actions-tab in-tab">
                    <b-dropdown variant="link" class="info" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                        <span class="left">
                                            <i class="icon icon-info"></i>
                                        </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-text>
                            <div class="info-button">
                                <span @click="infoCostForm(7)">{{ setLang('modules.budget.limit.expenses.*BudgetLimitsExpenses*.info-header') }} <i class="icon icon-folder"></i></span>
                            </div>
                            <div class="info-text">
                                <p>{{ setLang('modules.budget.limit.expenses.*BudgetLimitsExpenses*.info-part-one') }} </p>
                                <p>{{ setLang('modules.budget.limit.expenses.*BudgetLimitsExpenses*.info-part-two') }}</p>
                                <p><span class="red bold">{{ setLang('modules.budget.limit.expenses.*BudgetLimitsExpenses*.info-part-three') }}</span>
                                  <br>{{ setLang('modules.budget.limit.expenses.*BudgetLimitsExpenses*.info-part-four') }}</p>
                                <p><span class="red bold">{{ setLang('modules.budget.limit.expenses.*BudgetLimitsExpenses*.info-part-five') }}</span>
                                  <br>{{ setLang('modules.budget.limit.expenses.*BudgetLimitsExpenses*.info-part-six') }}</p>
                                <p><span class="red bold">{{ setLang('modules.budget.limit.expenses.*BudgetLimitsExpenses*.tab-name') }}</span><br>{{ setLang('modules.budget.limit.expenses.*BudgetLimitsExpenses*.info-part-seven') }}</p>
                            </div>
                        </b-dropdown-text>
                    </b-dropdown>
                    <b-dropdown variant="link" right toggle-class="text-decoration-none" no-caret>
                        <template #button-content>
                            <div class="btn-download">
                                <div>
                                    <span class="left">
                                        <i class="icon icon-download"></i> {{ setLang('modules.budget.limit.expenses.*BudgetLimitsExpenses*.download') }}
                                    </span>
                                    <i class="icon icon-keyboard"></i>
                                </div>
                            </div>
                        </template>
                        <b-dropdown-item @click="downloadSvodAbpReport">{{ setLang('modules.budget.limit.expenses.*BudgetLimitsExpenses*.report-abp-code') }}</b-dropdown-item>
                        <b-dropdown-item @click="downloadLimitReport">{{ setLang('modules.budget.limit.expenses.*BudgetLimitsExpenses*.report-bp-spending-limits') }}</b-dropdown-item>
                        <b-dropdown-item @click="downloadExpenseLimits">{{ setLang('modules.budget.limit.expenses.*BudgetLimitsExpenses*.report-abp-spending-limits') }}</b-dropdown-item>
                        <b-dropdown-item @click="referToFormForExcel">{{ setLang('modules.budget.limit.expenses.*BudgetLimitsExpenses*.report-coefficients-applied') }}</b-dropdown-item>
                    </b-dropdown>
                </div>
                <template>
                    <budget-limits-expenses ref="budgetlimits" @chgLimitData="chgLimitData" @infoSpravka="infoSpravka" @mainFormTranslate="mainFormTranslate"></budget-limits-expenses>
                </template>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import store from '../../services/store';
import {Ax} from '@/utils';
import LimitForm from '@/modules/budget/LimitForm';
import BudgetLimitsExpenses from "@/modules/budget-limitCosts/limitExpenses/BudgetLimitsExpenses.vue";
import BudgetDistribution from '@/modules/budget/budget-distribution.vue';
import {getActualVariant, loadVariantsWithEndDate, loadUserLevels, loadDataTypeDict} from '@/modules/budget/budgetVariantService';

export default {
    name: 'BudgetLimit',
    components: {LimitForm, 'c-budg-distr': BudgetDistribution, BudgetLimitsExpenses},
    data() {
        return {
            year: null,
            obl: null, // '45'
            region: null, // '450101',
            variant: null,
            tabIndx: 0,

            listReg: [],
            selectedABP: {id: 0, child: []},
            selectedPRG: {id: 0, child: []},
            selectedPPR: {id: 0},
            headers: null,
            currentYear: null,
            informSpravka: [],
            listVariants: [],
            userRegion: null,
            enableBudgDistr: false,
            enableLimitExpenses: false,
            editableBudgDistr: false,
            limitAccessLevel: 0,
            translationList: [],
            dataTypeDict: []
        };
    },

    async mounted() {
        this.year = this.currentYear = new Date().getFullYear();
        this.getModulesAccess();
        this.getLimitExpensesAccess();
        await this.getObl();
        await this.loadRegions();
        this.getUserLevels();
    },

    computed: {
        userUiid() {
            return store.state.user.sub;
        },
    },

    methods: {
        getTabName(){
          return this.$i18n.locale === 'kk' ? 'Шығындар' : (this.$i18n.locale === 'en' ? 'Expenses' : 'Расходы');
        },
        async getObl() {
            try {
                await fetch('/api-py/get-budget-obl/' + store.state._instanceCode)
                    .then(response => response.json())
                    .then(json => {
                        if (json.obl) this.obl = json.obl;
                        if (json.region) this.region = json.region;
                    });
                await getActualVariant(this.region).then(data => {
                    this.variant = data
                });
                await loadDataTypeDict().then(data => {
                    data.forEach(item => {
                        Object.defineProperty(item, 'text', {
                            get: function () {
                                return item.name_ru;    
                            }
                        })
                    })
                    this.dataTypeDict = data;
                })
                await loadVariantsWithEndDate().then(data => {
                    this.listVariants = data;
                });
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
            }
        },

        async loadRegions() {
            try {
                const response = await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + store.state.user.sub);
                this.listReg = await response.json()
                if (this.listReg.length > 0) {
                    this.userRegion = this.listReg[0].code;
                }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
            }
        }, // справочник регионов

        infoCostForm(info_id) {
            const that = this;
            Ax(
                {
                    url: '/api-py/get-info/' + info_id, //  Тело файла
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    let file = '';
                    if (info_id == 9) {
                        file = 'РП Лимиты доход';
                    }
                    if (info_id == 8) {
                        file = 'РП Лимиты нормативы распределения';
                    }
                    if (info_id == 7) {
                        file = 'РП Лимиты расход';
                    }
                    link.setAttribute('download', file + '.pdf');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },
        // формирование отчета
        downloadAgrExcel() {
            const that = this;
            Ax(
                {
                    url: '/api-py/agr-form-download/' + that.region + '/' + that.variant + '/' + that.year + '/'
                        + that.selectedABP.id + '/' + that.selectedPRG.id, //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Бюджет в агрегированной форме.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },
        // формирование отчета
        downloadExcel() {
            const that = this;
            Ax(
                {
                    url: '/api-py/summary-form-download/' + that.region + '/' + that.variant + '/' + that.year + '/'
                        + that.selectedABP.id + '/' + that.selectedPRG.id, //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Бюджет на предстоящий период.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },
        // формирование отчета
        downloadVedmExcel() {
            const that = this;
            Ax(
                {
                    url: '/api-py/ved-form-download/' + that.region + '/' + that.variant + '/' + that.year + '/'
                        + that.selectedABP.id + '/' + that.selectedPRG.id, //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Бюджет по ведомственной классиф.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },
        // формирование отчета
        downloadInExcel() {
            const that = this;
            Ax(
                {
                    url: '/api-py/in-form-download/' + that.region + '/' + that.variant + '/' + that.year + '/'
                        + that.selectedABP.id + '/' + that.selectedPRG.id, //  Тело файла
                    method: 'POST',
                    data: that.ajxParams,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'Доходная часть бюджета.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    that.error = error;
                }
            );
        },

        makeToast(variant, title, tostbody) {
            this.$bvToast.toast(tostbody, {
                title: title,
                variant: variant,
                toaster: 'b-toaster-top-center',
                autoHideDelay: 5000,
                appendToast: true
            });
        }, // сообщение

        chgLimitData(data, obl, region) {
            const yearFact = (data.year - 2).toString();
            const yearPlan = (data.year - 1).toString();
            this.headers = {
                year: data.year,
                yearFact: yearFact,
                yearPlan: yearPlan,
                data_type: data.data_type,
                abp: data.abp,
                prg: data.prg,
                ppr: data.ppr,
                ga: data.ga,
                obl: obl,
                region: region
            };
        },
        mainFormTranslate(arr){
          this.translationList = arr;
        },
        //------------Создается объект с информативной справкой--------//
        infoSpravka(informationArr, fullInformationListArr){
            // console.log('informationArr: ' + JSON.stringify(informationArr));
            const details = {
                order: '',
                title: '',
                curYear: '',
                valYearOne: '',
                valYearTwo: '',
                valYearThree: '',
                spf: '',
            }

            if (informationArr) {
                const spravkaArr = [];
                const spfArr1 = [];
                const spfArr2 = [];
                let count = 2;
                for (const inform of informationArr) {
                    if (inform.specific === true | inform.spf ==="") {
                        details.order = count++;
                        details.title = inform.title;
                        details.curYear = inform.firstYear;
                        details.valYearOne = inform.valYearOne;
                        details.valYearTwo = inform.valYearTwo;
                        details.valYearThree = inform.valYearThree;
                        details.spf = inform.spf;
                        if (details.spf == ""){
                            details.order = 1;
                        }
                        spfArr1.push(details.spf);
                        spravkaArr.push(JSON.parse(JSON.stringify(details)));
                    }
                }
                //-----Извлекаю только spf из второго массива для последующего сравнения---//
                if (fullInformationListArr.length>0){
                    for (const fullInfo of fullInformationListArr){
                        spfArr2.push(fullInfo.spf);
                    }
                }

                let arrDiff = [];
                arrDiff.length=0;
                arrDiff = this.arrayDifference(spfArr2, spfArr1); //----Извлекаю элементы spf, которые не совпали в 2 массивах
                const arrDiffList = this.getOnlySingleSpf(arrDiff);
                //----------Добавляю список элементов spf в "Индекс инфляции"
                if (arrDiff.length>0){
                    for (const spravka of spravkaArr){
                        if (spravka.spf === ""){
                            let spfList = "";
                            //----Проставляю запятые после каждого spf в "Индексе инфляции"
                            for (let i=0; i<arrDiffList.length; i++){
                                spfList += arrDiffList[i];
                                if (i<arrDiffList.length-1){
                                    spfList += ', ';
                                }
                            }
                            spravka.spf = spfList;
                        }
                    }
                }
                this.informSpravka = spravkaArr;
            }
            // console.log('this.informSpravka: ' + JSON.stringify(this.informSpravka));
        },
        //------------Извлекается разница между двумя массивами---------//
        arrayDifference(arr1, arr2) {
            const differenceArr = arr1.filter(i => arr2.indexOf(i) < 0);
            return differenceArr;
        },

        // -----Функция для очистки массива "SPF" от дубликатов
        getOnlySingleSpf(arr) {
            const filteredObjectArr = arr.reduce((enteredArr, current) => {
                const x = enteredArr.find(item => item === current);
                if (!x) {
                    return enteredArr.concat([current]);
                } else {
                    return enteredArr;
                }
            }, []);
            return filteredObjectArr;
        },

        currentYearData(curYear) {
            if (curYear !== 'undefined' || curYear !== null) {
                this.currentYear = curYear;
            }
        },

        async downloadLimitReport() {
            if (this.headers && Object.keys(this.headers).length !== 0) {
                Ax(
                    {
                        url: '/api-py/budget_limits/' + this.headers.year + '/' + this.headers.yearFact + '/' + this.headers.yearPlan + '/' + this.headers.obl + '/' + this.headers.region + '/' + this.headers.abp + '/' + this.headers.prg + '/' + this.headers.ppr, //  Тело файла
                        method: 'POST',
                        data: null,
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Лимиты по расходам.xls');// or any other extension
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.error = error;
                    }
                );

            }
        },

        async incomeGenerationPSER() {
            //--------------------------------------------------------------------------------------------------------//
            const paramsNew = JSON.stringify({ curYear: parseInt(this.currentYear), obl: this.obl, region: this.userRegion });
            const urlNew = '/api-py/budget_income_pser/'+encodeURI(paramsNew);
            Ax(
                {
                    url: urlNew, //  Тело файла
                    method: 'POST',
                    data: null,
                    responseType: 'blob'
                },
                (data) => {
                    const url = window.URL.createObjectURL(new Blob([data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'отчеты Формирование доходов ПСЭР.xls');// or any other extension
                    document.body.appendChild(link);
                    link.click();
                },
                (error) => {
                    this.error = error;
                }
            );
        },

        async downloadSvodAbpReport() {
            if (this.headers && Object.keys(this.headers).length !== 0) {
                Ax(
                    {
                        url: '/api-py/svod_abp/' + this.headers.year + '/' + this.headers.yearFact + '/' + this.headers.yearPlan + '/' + this.headers.obl + '/' + this.headers.region + '/' + this.headers.abp + '/' + this.headers.prg + '/' + this.headers.ppr, //  Тело файла
                        method: 'POST',
                        data: null,
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Свод по АБП.xls');// or any other extension
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.error = error;
                    }
                );
            }
        },

        referToFormForExcel(){
            const informs = this.informSpravka;
            if (Object.keys(this.informSpravka).length !== 0) {
                const params = JSON.stringify(informs);
                Ax(
                    {
                        url: '/api-py/budget_information_sheet/'+encodeURI(params), //  Тело файла
                        method: 'POST',
                        data: null,
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Информация о применяемых коэффициентах.xls');// or any other extension
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.error = error;
                    }
                );
            }
        },

        async downloadExpenseLimits() {
            if (this.headers && Object.keys(this.headers).length !== 0) {
                Ax(
                    {
                        url: '/api-py/expense_limits_abp/' + this.headers.year + '/' + this.headers.yearFact + '/' + this.headers.yearPlan + '/' + this.headers.obl + '/' + this.headers.region + '/' + this.headers.abp + '/' + this.headers.prg + '/' + this.headers.ppr, //  Тело файла
                        method: 'POST',
                        data: null,
                        responseType: 'blob'
                    },
                    (data) => {
                        const url = window.URL.createObjectURL(new Blob([data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'Лимиты расходов АБП.xls');// or any other extension
                        document.body.appendChild(link);
                        link.click();
                    },
                    (error) => {
                        this.error = error;
                    }
                );
            }
        },
        // ------------------
        getModulesAccess() {
            const access = this.getAccess('004.001.002');
            this.enableBudgDistr = access.enable;
            this.editableBudgDistr = access.editable;
        },

        getLimitExpensesAccess() {
          const access = this.getAccess('004.001.003')
          this.enableLimitExpenses = access.enable;
          // console.log('this.enableLimitExpenses: ' + JSON.stringify(this.enableLimitExpenses));
        },

        getAccess(code) {
            const modules = store.state.user.userModules;
            let editable = false;
            let enable = false;
            for (const row of modules) {
                if (row.modules === code) {
                    if (row.access_level !== 1) {
                        editable = true;
                    }
                    if ([1, 2, 3].includes(row.access_level)) {
                        enable = true;
                    }
                }
            }
            return { editable, enable };
        },

        async getUserLevels() {
            if (this.userUiid) {
                let response = [];
                try {
                    await loadUserLevels(this.userUiid).then(data => {
                        response = data;
                    });
                    for (const row of response) {
                        switch (row.modules) {
                            case "004.001.001":
                                this.limitAccessLevel = row.access_level;
                                break;
                            default:
                                break;
                        }
                    }
                } catch (error) {
                    this.makeToast(
                        "danger",
                        "Ошибка getUserLevels",
                        error.toString(),
                    );
                }
            }
        },

        setLang(code){
          for (const item of this.translationList){
            if (item.code === code) {
              item.title = this.$i18n.locale === 'kk' ? item.kk : (this.$i18n.locale === 'en' ? item.en : item.ru);
              return item.title;
            }
          }
        }
    }
};
</script>
<style>
.is-hidden {
    display: none !important;
}
</style>